<template>
  <div>
    <a-modal
      width="800px"
      v-model="isShow"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="查看客户短信模版"
      @ok="handleSubmit"
    >
      <a-spin :spinning="spinning">
        <a-form
          class="simiot-compact-form"
          :form="form"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 15 }"
          @submit="handleSubmit"
        >
          <a-row>
            <!--基本信息， 周期容量设置-->
            <a-col :span="24">
              <span>基本信息</span>
              <a-form-item
                label="模版名称"
                class="simiot-display-item"
              >
                {{ data.name }}
              </a-form-item>

              <a-form-item
                label="模版内容"
                class="simiot-display-item"
              >
                {{ data.content }}
              </a-form-item>

              <a-form-item
                label="备注"
                class="simiot-display-item"
              >
                {{ data.remark }}
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </a-spin>
    </a-modal>
  </div>
</template>

<script>
import { findAgentsSmsTemplateForm } from '@/api/agents_sms_template'

export default {
  name: 'EditAgentsSmsTemplate',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      data: {},
      submitting: false,
      spinning: false,
      form: this.$form.createForm(this, { name: 'agents_sms_template' })
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },

    isSaleLogin() {
      return this.$store.getters.userRole === 'sale' || this.$store.getters.userRole === 'sale_assistant'
    }
  },
  created() {
    this.form.getFieldDecorator('keys', { initialValue: [0], preserve: true })
    this.fetchFormData()
  },
  methods: {
    fetchFormData() {
      this.spinning = true
      findAgentsSmsTemplateForm(this.id).then((res) => {
        this.data = res.data

        this.form.setFieldsValue({
          name: this.data.name,
          content: this.data.content,
          remark: this.data.remark
        })

        this.spinning = false
      })
    },

    handleSubmit(e) {
      e.preventDefault()
      this.isShow = false
    }
  }
}
</script>
